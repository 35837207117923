import { Typography } from '@material-ui/core';
import React from 'react';

import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { useGlobalStyles } from '../../utils/styles';
import { useStyles } from '../../utils/tu-instituto/styles';

const Mensaje = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Mensaje" />

      <div className={globalClasses.container}>
        <Header title="Mensaje del encargado de despacho de la Dirección General" />

        <div className={classes.message}>
          <div className={classes.image}>
            <img  src={require('../../assets/images/doctor/LC-Alipi-Mena.png')} />
          </div>
          <div className={classes.text}>
            <Typography variant="body1">
              El Instituto de Seguridad Social del Estado de Tabasco tiene como misión garantizar las prestaciones médicas, sociales y económicas, así como fomentar actividades culturales y deportivas a los trabajadores al servicio del estado y sus municipios, pensionados y beneficiarios.
            </Typography>
            <Typography variant="body1">
              El quehacer primordial es brindar la atención integral en seguridad social, recuperando los valores institucionales de honestidad, legalidad y principios éticos.
            </Typography>
            <Typography variant="body1">
              Nuestra tarea es construir un instituto moderno y eficiente, cuyo objetivo es proteger el patrimonio de los trabajadores bajo una nueva cultura de servicio que privilegie el respeto a sus derechos y el reconocimiento de sus responsabilidades como ciudadanos.
            </Typography>
            <Typography variant="body1">
              Un Instituto que continua consolidando los procesos internos, siempre manteniendo al centro a los Derechohabientes.
            </Typography>
            <Typography variant="body1">
              El portal web del Instituto de Seguridad Social del Estado de Tabasco, que presentamos, forma parte del compromiso con la innovación, transparencia y rendición de cuentas de esta nueva administración.
            </Typography>
            <Typography variant="body1">
              Es un instrumento de comunicación que pone a disposición de los derechohabientes, diversos trámites, avisos y servicios en línea para agilizar los procesos de mejora. Asimismo, difundir información relacionada con las actividades del instituto en un esquema de retroalimentación
            </Typography>
            <Typography variant="body1">
            Continuaremos trabajando arduamente para recuperar la razón de ser del instituto, bajo los ejes transversales: combate a la corrupción, fomentar la calidad en la gestión pública y una política de inclusión, así como el saneamiento y estabilidad de las finanzas a fin de impulsar la cultura de la seguridad social en el estado, reto que enfrentamos y enfrentaremos hasta consolidarlo.
            </Typography>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mensaje;